import {
  EXPERIMENT_PROVISION_WITHOUT_NAVIGATION,
  EXPERIMENT_BLOG_PROVISIONING_MODAL,
  EXPERIMENT_NEW_MEMBERS_AREA_INSTALL,
} from '@wix/communities-blog-experiments';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { blogAppDefId, membersAreaAppDefId } from '../constants/apps';
import { PROVISIONING_MODAL } from '../constants/modal-panels';
import { getPanelOptions } from './settings-panels';
import { ALL_SECTIONS, SECTIONS_WITHOUT_ALL_MEMBERS } from '../constants/sections';
import { TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import dependentApps from '../constants/dependent-apps';
import addPage from './add-page';
import bi from './bi';
import { getSiteMemberId } from './instance';
import sections from './sections';
import monitoring from './monitoring';
import experiments from './experiments';
import page from './page';
import memberPages from '../constants/member-pages';
import { LAYOUT_PANEL, MAIN_PANEL } from '../constants/settings-panels';
import { ARCHIVE_LIST_WIDGET_ID } from '../constants/widgets';
import { openModalPanel } from './modal';

export const getMembersApi = async ({ sdk, appToken }) =>
  sdk.application.getPublicAPI(appToken, { appDefinitionId: membersAreaAppDefId });

const navigateToBlogPage = async ({ sdk, appToken }) => {
  const blogPage = await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_BLOG });
  return blogPage && sdk.document.pages.navigateTo(appToken, { pageLink: { type: 'PageLink', pageId: blogPage.id } });
};

export const installMissingPostPage = async ({ sdk, appToken, instance }) => {
  const userId = await getSiteMemberId(sdk, appToken);
  if (userId === AUTOPILOT_USER_UUID) {
    return;
  }

  let currentPage, isPostPageMissing;
  try {
    [currentPage, isPostPageMissing] = await Promise.all([
      sdk.document.pages.getPrimary(),
      page.isPostPageMissing({ sdk, appToken, instance }),
    ]);
  } catch (e) {
    console.log('Error checking for missing post page:');
    console.log(e);
  }

  if (isPostPageMissing) {
    await sdk.tpa.add.component(appToken, {
      componentType: 'PAGE',
      appDefinitionId: blogAppDefId,
      page: { pageId: TPA_PAGE_ID_POST },
    });

    return sdk.document.pages.navigateTo(appToken, { pageRef: currentPage });
  }

  return Promise.resolve();
};

export const initProvision = async ({ sdk, appToken }) => {
  const provisionWithoutNavigation = await experiments.isEnabled(EXPERIMENT_PROVISION_WITHOUT_NAVIGATION);
  if (!provisionWithoutNavigation) {
    await monitoring.toMonitored('navigate-to-blog-page', navigateToBlogPage({ sdk, appToken }));
  }
  return await monitoring.toMonitored('sdk.document.save', sdk.document.save());
};

export const initBiService = async ({ sdk, appToken }) => {
  bi.init(await sdk.document.info.getAppInstance(appToken));
};

export const displayProvisioningModal = async ({ sdk, appToken, isADI }) => {
  try {
    bi.provisioningModalDisplayed();
    const { action, value } = await openModalPanel({ sdk, appToken, isADI, modal: PROVISIONING_MODAL });
    bi.provisioningModalClosed(action, value);

    const shouldInstallMembersArea = action === 'next' && value;
    if (shouldInstallMembersArea) {
      return monitoring.toMonitored(
        'install-members-area',
        sdk.application.install(appToken, {
          appDefinitionId: membersAreaAppDefId,
          initiatorAppDefinitionId: blogAppDefId,
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
};

export const installBlogSections = async ({ sdk, appToken }) => {
  const isInstalled = await sdk.tpa.isApplicationInstalled(appToken, { appDefinitionId: blogAppDefId });
  if (!isInstalled) {
    return;
  }
  await initProvision({ sdk, appToken });

  const [membersAPI, isProvisioningModalEnabled, isParallelInstallationEnabled] = await Promise.all([
    getMembersApi({ sdk, appToken }),
    experiments.isEnabled(EXPERIMENT_BLOG_PROVISIONING_MODAL),
    experiments.isEnabled(EXPERIMENT_NEW_MEMBERS_AREA_INSTALL),
  ]);

  const sectionNames = isProvisioningModalEnabled ? SECTIONS_WITHOUT_ALL_MEMBERS : ALL_SECTIONS;

  if (isParallelInstallationEnabled) {
    const apps = sectionNames.map(name => memberPages[name] || dependentApps[name]);
    return (
      membersAPI &&
      monitoring.toMonitored(
        'install-members-area-apps-in-parallel',
        sections.installInParallel(membersAPI, apps).then(() => setDependantApps({ sdk, appToken })),
      )
    );
  }

  return (
    membersAPI &&
    monitoring.toMonitored(
      'handle-members-area-installed',
      sections
        .installInSequence(addPage.createAddPage(membersAPI), sectionNames)
        .then(() => setDependantApps({ sdk, appToken })),
    )
  );
};

export async function setDependantApps({ sdk, appToken }) {
  const membersAPI = await getMembersApi({ sdk, appToken });
  const dependencies = Object.keys(dependentApps).map(app => dependentApps[app].appDefinitionId);
  return monitoring.toMonitored(
    'set-dependant-apps',
    membersAPI
      ? membersAPI.setDependantApps({
          appId: blogAppDefId,
          dependencies,
        })
      : Promise.resolve(),
  );
}

export async function addBlogWidget({ sdk, appToken }) {
  const homepage = await sdk.document.pages.getHomePage(appToken);

  const componentDefinition = {
    componentType: 'wysiwyg.viewer.components.RefComponent',
    type: 'RefComponent',
    data: {
      type: 'WidgetRef',
      appDefinitionId: blogAppDefId,
      widgetId: ARCHIVE_LIST_WIDGET_ID,
    },
  };
  console.log('lets add component');
  const comp = await sdk.document.components.add(appToken, { componentDefinition, pageRef: homepage });
  if (comp) {
    console.log('Component added to homepage!');
  }
}

const openSettingsPanel = panelType => async ({ sdk, appToken, instance }, componentRef, componentRole) => {
  const options = await getPanelOptions({ sdk, instance, componentRef, panelType, componentRole });

  return sdk.editor.openComponentPanel(appToken, {
    componentRef,
    ...options,
  });
};

const openMainSettings = openSettingsPanel(MAIN_PANEL);
const openLayoutSettings = openSettingsPanel(LAYOUT_PANEL);

export default {
  getMembersApi,
  initProvision,
  initBiService,
  installBlogSections,
  installMissingPostPage,
  setDependantApps,
  displayProvisioningModal,
  addBlogWidget,
  openLayoutSettings,
  openMainSettings,
};
